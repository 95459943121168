import React, { useEffect, useState } from 'react';
import { Box, Button, Card, CardActions, CardContent, CircularProgress, TextField, Typography } from '@mui/material';
import { useLocation } from 'react-router-dom';

const Register: React.FC = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [linkConvite, setLinkConvite] = useState('');

  const location = useLocation(); // Usando o useLocation para pegar os parâmetros da URL

  useEffect(() => {
    // Pega o parâmetro de convite da URL
    const params = new URLSearchParams(location.search);
    const link = params.get('codigoIndicacao');
    if (link) {
      setLinkConvite(link); // Armazena o link de convite
    }
  }, [location]);

  const handleRegister = () => {
    // Função para lidar com o registro
  };

  return (
    <Box width="100vw" height="100vh" display="flex" alignItems="center" justifyContent="center">
      <Card>
        <CardContent>
          <Box display="flex" flexDirection="column" gap={2} width={300}>
            <Typography variant="h6" align="center">
              Registre-se
            </Typography>
            <TextField fullWidth label="Nome Completo" />
            <TextField fullWidth label="Email" />
            <TextField fullWidth label="Senha" type="password" />
            <TextField fullWidth label="Confirmar Senha" type="password" />

            
          </Box>
        </CardContent>
        <CardActions>
          <Box width="100%" display="flex" justifyContent="space-between" padding={2}>
            <Button variant="contained" disabled={isLoading} onClick={handleRegister}>
              Registrar
            </Button>
          </Box>
        </CardActions>
      </Card>
    </Box>
  );
};

export default Register;
