
export const Environment = {
  /**
   * Define a quantidade de linhas a ser carregada por padrão nas listagens
   */
  LIMITE_DE_LINHAS: 100,
  /**
   * Placeholder exibido nas inputs
   */
  INPUT_DE_BUSCA: 'Pesquisar...',
  /**
   * Texto exibido quando nenhum registro é encontrado em uma listagem
   */
  LISTAGEM_VAZIA: 'Nenhum registro encontrado.',
  /**
   * Url base de consultado dos dados dessa aplicação
   */
  //URL_BASE: 'http://localhost:8080/',
  //URL_BASE: 'https://bit-hunt-back-dev-674a2f22b7//.herokuapp.com',
  URL_BASE: 'https://bit-hunt-back-prod-967709cb90be.herokuapp.com/',
  //URL_BASE_FRONT: 'http://localhost:3000/',
  URL_BASE_FRONT: 'https://www.bithunt.com.br/',
};
