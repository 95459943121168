import React, { useEffect, useState } from 'react';
import { Box, Button, List, ListItem, ListItemText, Paper, Typography, Dialog, DialogTitle, DialogContent, DialogActions, TextField } from '@mui/material';
import { UserService } from '../../shared/services/api/users/UserService';
import { LayoutBaseDePagina } from '../../shared/layouts';
import { FerramentasDaListagem } from '../../shared/components';
import { useAuthContext } from '../../shared/contexts';
import { Environment } from '../../shared/environment';

export interface IUIndication {
  id: string;
  fullName: string;
}

const ListaIndicados: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);
  const [linkGerado, setLinkGerado] = useState('');
  const [indicadoList, setIndicadosList] = useState<IUIndication[]>([]); // Inicializado corretamente como array
  const [isLoadingProcessing, setIsLoadingProcessing] = useState(true);
  const { isAuthenticated,  } = useAuthContext();
  const [uIID, setUIID] = useState<string>();

  // Função para gerar o link com um código de indicação
  const gerarLink = () => {

    const baseUrl = Environment.URL_BASE;
    const link = `${Environment.URL_BASE_FRONT}registrar?isRegistering=true&codigoIndicacao=${uIID}&isInicio=false`;
    setLinkGerado(link);
    setOpenModal(true); // Abre o modal
  };

  // Busca o processamento do usuário
  useEffect(() => {
    setIsLoadingProcessing(true); // Liga o loading
  
    UserService.getIndication()
      .then((result) => {
        setIsLoadingProcessing(false); // Desliga o loading
  
        if (result instanceof Error) {
          alert(result.message);
        } else {     
  
            setIndicadosList(result.indications); // Atualiza a lista de indicações
            setUIID(result.id); // Atualiza os IDs (se necessário, escolha como manipular múltiplos IDs)
          
        }
      });
  }, []);
  


  return (

    <LayoutBaseDePagina
            titulo={"Lista de Indicados"}
            barraDeFerramentas={<FerramentasDaListagem mostrarBotaoNovo={false} />}
        >
    <Box padding={2} component={Paper} elevation={3}>
      <Box display="flex" justifyContent="space-between" alignItems="center" marginBottom={2}>
        <Typography variant="h4">Indicados</Typography>
        <Button variant="contained" color="primary" onClick={gerarLink}>
          Convidar
        </Button>
      </Box>

      <List>
        {indicadoList.map((indicado: IUIndication) => (
          <ListItem key={indicado.id} divider>
            <ListItemText primary={indicado.fullName} />
          </ListItem>
        ))}
      </List>

      {/* Modal para exibir o link gerado */}
      <Dialog open={openModal} onClose={() => setOpenModal(false)}>
        <DialogTitle>Link de Convite Gerado</DialogTitle>
        <DialogContent>
          <TextField
            label="Link"
            fullWidth
            value={linkGerado}
            InputProps={{
              readOnly: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenModal(false)} color="secondary">
            Fechar
          </Button>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(linkGerado);
              alert('Link copiado para a área de transferência!');
            }}
            color="primary"
          >
            Copiar Link
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
    </LayoutBaseDePagina>
  );
};

export default ListaIndicados;
