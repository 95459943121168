import { createTheme } from '@mui/material';
import { lime, pink, teal } from '@mui/material/colors';

export const Cyberpunk = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: lime[500],
      dark: lime[700],
      light: lime[300],
      contrastText: '#000000',
    },
    secondary: {
      main: pink[500],
      dark: pink[700],
      light: pink[300],
      contrastText: '#ffffff',
    },
    background: {
      paper: '#212121',
      default: '#121212',
    },
    text: {
      primary: '#ffffff', // Texto branco para contraste no fundo escuro
      secondary: teal[300],
    },
  },
  typography: {
    fontFamily: "'Press Start 2P', monospace",
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '8px',
          border: '2px solid',
          borderColor: lime[500],
          textShadow: '0 0 5px lime',
          '&:hover': {
            backgroundColor: lime[700],
            borderColor: lime[300],
            textShadow: '0 0 10px lime',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#1a1a1a',
          color: lime[300],
          boxShadow: '0 0 15px pink',
        },
      },
    },
  },
});
