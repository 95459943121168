import { createContext, useCallback, useContext, useMemo, useState } from 'react';
import { ThemeProvider } from '@mui/material';
import { Box } from '@mui/system';

import { Cyberpunk, LightCyberpunkTheme } from './../themes';

interface IThemeContextData {
  themeName: 'light' | 'dark';
  toggleTheme: () => void;
}

const ThemeContext = createContext({} as IThemeContextData);

export const useAppThemeContext = () => {
  return useContext(ThemeContext);
};

interface IAppThemeProviderProps {
  children: React.ReactNode
}

export const AppThemeProvider: React.FC<IAppThemeProviderProps> = ({ children }) => {
  // Recuperar o tema inicial do localStorage ou definir o padrão como 'light'
  const [themeName, setThemeName] = useState<'light' | 'dark'>(() => {
    const savedTheme = localStorage.getItem('app-theme');
    return (savedTheme === 'dark' || savedTheme === 'light') ? savedTheme : 'light';
  });

  // Alternar tema e salvar no localStorage
  const toggleTheme = useCallback(() => {
    setThemeName(oldThemeName => {
      const newThemeName = oldThemeName === 'light' ? 'dark' : 'light';
      localStorage.setItem('app-theme', newThemeName); // Salvar escolha no localStorage
      return newThemeName;
    });
  }, []);

  // Atualizar o tema com base no estado
  const theme = useMemo(() => {
    return themeName === 'light' ? LightCyberpunkTheme : Cyberpunk;
  }, [themeName]);

  return (
    <ThemeContext.Provider value={{ themeName, toggleTheme }}>
      <ThemeProvider theme={theme}>
        <Box width="100vw" height="100vh" bgcolor={theme.palette.background.default}>
          {children}
        </Box>
      </ThemeProvider>
    </ThemeContext.Provider>
  );
};
