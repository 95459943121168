import { Box, Card, CardContent, Avatar, Typography, Paper } from '@mui/material';
import { LayoutBaseDePagina } from '../../shared/layouts';

export const SobreNos = () => {
    const integrantes = [
        {
            nome: 'Arthur',
            avatar: 'path/to/avatar_arthur.jpg', // Atualize para o caminho da foto do avatar
            role: 'Desenvolvedor Front-End',
            descricao: 'Desenvolvedor React e criação de interfaces intuitivas, com 2 anos de experiência.',
        },
        {
            nome: 'Guilherme',
            avatar: 'path/to/avatar_guilherme.jpg', // Atualize para o caminho da foto do avatar
            role: 'Desenvolvedor Front-End',
            descricao: 'Programador React, node.js.',
        },
        {
            nome: 'Kleber',
            avatar: 'path/to/avatar_kleber.jpg',
            role: 'Desenvolvedor Unity',
            descricao: 'Com mais de 6 anos de experiência em desenvolvimento de jogos 2D, domina Unity e C#.',
        },
        {
            nome: 'Talles',
            avatar: 'path/to/avatar_talles.jpg',
            role: 'Especialista em Blockchain e Infraestrutura',
            descricao: 'Expert em contratos inteligentes, segurança blockchain e arquiteturas distribuídas, com 4 anos na área.',
        },
        {
            nome: 'Ramon',
            avatar: 'path/to/avatar_ramon.jpg',
            role: 'Desenvolvedor Java e Gerente de Projeto',
            descricao: 'Profissional com 14 anos de experiência em Java, 2 anos em React, gestão de equipes e metodologias ágeis.',
        },
        {
            nome: 'Fernando',
            avatar: 'path/to/avatar_fernando.jpg', // Atualize para o caminho da foto do avatar
            role: 'Programador Pleno',
            descricao: 'Desenvolvedor com 5 anos de experiência em Java e projetos de grande porte, atuando em soluções robustas e escaláveis.',
        },
        {
            nome: 'Luccas',
            avatar: 'path/to/avatar_luccas.jpg', // Atualize para o caminho da foto do avatar
            role: 'UX Estagiário',
            descricao: 'Estudante de design de experiência do usuário, com foco em pesquisa de usuários e prototipagem de interfaces.',
        },
    ];

    return (
        <LayoutBaseDePagina titulo="Sobre Nós" barraDeFerramentas={null}>
            <Box width="100%" display="flex" flexDirection="column" alignItems="center" padding={0} >
                {/* Introdução */}
                <Box width="100%" maxWidth="1000px" marginBottom={4} textAlign="center" component={Paper} margin={3}>
                    <Typography variant="h4" gutterBottom color="secondary">
                        Apaixonados por tecnologia
                    </Typography>
                    <Typography variant="body1" paragraph margin={5}>
                        Somos uma equipe dedicada e entusiasmada por inovação tecnológica. Nosso foco agora é mergulhar no fascinante mundo da blockchain.
                        Este desafio é mais do que resolver problemas técnicos; é uma jornada repleta de diversão, a chance de conquistar prêmios em cripto ao encontrar chaves privadas
                        e a oportunidade de entender profundamente como as blockchains transformam o mundo digital.

                    </Typography>

                </Box>

                {/* Título da Equipe */}
                <Typography variant="h4" align="center" gutterBottom color="secondary">
                    Nossa Equipe
                </Typography>

                {/* Cards dos Integrantes */}
                <Box
                    display="flex"
                    flexWrap="wrap"
                    justifyContent="center"
                    gap={2}
                    width="100%"
                    maxWidth="1200px"
                >
                    {integrantes.map((integrante, index) => (
                        <Card key={index} sx={{ maxWidth: 300 }}>
                            <CardContent>
                                <Box display="flex" flexDirection="column" alignItems="center">
                                    <Avatar
                                        src={integrante.avatar}
                                        alt={`Avatar de ${integrante.nome}`}
                                        sx={{ width: 100, height: 100, marginBottom: 2 }}
                                    />
                                    <Typography variant="h6" gutterBottom>
                                        {integrante.nome}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" gutterBottom>
                                        {integrante.role}
                                    </Typography>
                                    <Typography variant="body1" textAlign="center">
                                        {integrante.descricao}
                                    </Typography>
                                </Box>
                            </CardContent>
                        </Card>
                    ))}
                </Box>
            </Box>
        </LayoutBaseDePagina>
    );
};
