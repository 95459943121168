import { AxiosError } from 'axios';
import { Api } from '../axios-config';

interface IAuth {
  accessToken: string;
}

// Função para autenticar o usuário (Login)
const auth = async (username: string, password: string): Promise<IAuth | Error> => {
  try {
    // Realiza a requisição de login
    const { data } = await Api.post('/auth/login', { username, password });

    if (data) {
      return data;
    }

    return new Error('Erro no login.');
  } catch (error) {
    if (error instanceof AxiosError) {
      return new Error(error.response?.data);
    }
    return new Error((error as { message: string }).message || 'Erro no login.');
  }
};

// Função para verificar se o usuário está autenticado
const checkAuth = async (): Promise<boolean> => {
  try {
    // Endpoint que valida o status do usuário (exige o cookie JWT)
    await Api.get('/auth/check');
    return true; // Se a requisição foi bem-sucedida, o usuário está autenticado
  } catch (error) {
    console.error('Erro na verificação de autenticação:', error);
    return false; // Se a verificação falhar, o usuário não está autenticado
  }
};

// Função para realizar logout
const logout = async (): Promise<void> => {
  try {
    // Endpoint que realiza logout no servidor (limpa o cookie JWT)
    await Api.post('/auth/logout');
  } catch (error) {
    console.error('Erro ao fazer logout:', error);
  }
};

export const AuthService = {
  auth,
  checkAuth,
  logout,
};
