import { useEffect, useState } from 'react';
import { Box, Card, CardContent, Grid, Typography } from '@mui/material';

import { UserService } from '../../shared/services/api/users/UserService';
import { FerramentasDaListagem } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';
import DownloadButton from '../../shared/components/download-button/DownloadButton';

// Interfaces de dados
interface IUserInterface {
    id: number;
    email: string;
    cidadeId: number;
    nomeCompleto: string;
    enabled: boolean;
    createdAt: string;
    updatedAt: string;
}


export const Download = () => {
    const [isLoadingPessoas, setIsLoadingPessoas] = useState(true);

    const [userInfo, setUserInfo] = useState<IUserInterface | null>(null);

    // Busca as informações do usuário
    useEffect(() => {
        setIsLoadingPessoas(true);
        UserService.getMe()
            .then((result) => {
                setIsLoadingPessoas(false);

                if (result instanceof Error) {
                    alert(result.message);
                } else {
                    setUserInfo(result);
                }
            });
    }, []);

    return (
        <LayoutBaseDePagina
            titulo={"Download Miner"}
            barraDeFerramentas={<FerramentasDaListagem mostrarBotaoNovo={false} />}
        >
            <Box width="100%" display="flex">
                <Grid container margin={2}>
                    <Grid item container spacing={2}>
                        {/* Card de Informações do Usuário */}
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" align="center">
                                        Informações do Usuário
                                    </Typography>

                                    <Box padding={2} display="flex" flexDirection="column" alignItems="center">
                                        {!isLoadingPessoas && userInfo && (
                                            <>
                                                <Typography variant="body1">
                                                    <strong>Email:</strong> {userInfo.email}
                                                </Typography>
                                                <Typography variant="body1">
                                                    <strong>Criado em:</strong> {new Date(userInfo.createdAt).toLocaleDateString()}
                                                </Typography>
                                            </>
                                        )}
                                        {isLoadingPessoas && (
                                            <Typography variant="h6">
                                                Carregando...
                                            </Typography>
                                        )}
                                    </Box>
                                </CardContent>
                            </Card>
                        </Grid>
                        {/* Card de Processamento do Usuário */}
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" align="center">
                                        Miner Windows
                                    </Typography>

                                    <Box padding={2} display="flex" flexDirection="column" alignItems="center">
                                        <DownloadButton
                                            fileUrl="/buildWin002PROD.zip"
                                            fileName="buildWin002PROD.zip"
                                            buttonText="Download Windows"
                                            style={{ fontSize: '18px' }}  // Estilo personalizado
                                        />
                                    </Box>

                                </CardContent>
                            </Card>
                        </Grid>

                        {/* Card de Processamento do Usuário */}
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" align="center">
                                        Miner Windows Python
                                    </Typography>

                                    <Box padding={2} display="flex" flexDirection="column" alignItems="center">
                                        <DownloadButton
                                            fileUrl="/buildWin003PROD.zip"
                                            fileName="buildWin003PROD.zip"
                                            buttonText="Download Windows"
                                            style={{ fontSize: '18px' }}  // Estilo personalizado
                                        />
                                    </Box>

                                </CardContent>
                            </Card>
                        </Grid>
                        {/* Card de Processamento do Usuário */}
                        <Grid item xs={12} sm={12} md={6} lg={4} xl={3}>
                            <Card>
                                <CardContent>
                                    <Typography variant="h5" align="center">
                                        Miner Android
                                    </Typography>

                                    <Box padding={2} display="flex" flexDirection="column" alignItems="center">
                                        <DownloadButton
                                            fileUrl="/BUILD_V002_PROD.apk"
                                            fileName="BUILD_V002_PROD.apk"
                                            buttonText="Download Android"
                                            style={{ fontSize: '18px' }}  // Estilo personalizado
                                        />
                                    </Box>

                                </CardContent>
                            </Card>
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </LayoutBaseDePagina>
    );
};
