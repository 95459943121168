import { Box, Typography, CardContent, Card, Button } from '@mui/material';
interface InicioProps {
    setInicio: (value: boolean) => void;
}

export const Inicio: React.FC<InicioProps> = ({ setInicio }) => {
    // Valor de Bitcoin em USD (cotação fictícia, substitua pela real se necessário)
    const bitcoinToUsd = 37000; // Atualize com o valor do Bitcoin em tempo real, se necessário
    const recompensaEmUsd = (6.60128491 * bitcoinToUsd).toFixed(2);

    return (
        <>
            <Card>
                <CardContent>
                    {/* Título */}
                    <Box marginBottom={4}>
                        <Typography variant="h3" align="center" gutterBottom>
                            Projeto de Mineração Distribuída
                        </Typography>
                    </Box>

                    {/* Introdução */}
                    <Box maxWidth="800px" marginBottom={4}>
                        <Typography variant="body1" paragraph>
                            Nosso projeto se baseia na resolução de um quebra-cabeça envolvendo carteiras Bitcoin. Criado em 2015, este desafio premia quem conseguir quebrar as chaves privadas de endereços específicos.
                            Atualmente, estamos no <strong>Puzzle #67</strong>, que possui uma recompensa de <strong>6.60128491 BTC</strong>, equivalente a aproximadamente <strong>${recompensaEmUsd} USD</strong>.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Para participar, você precisa se cadastrar, baixar nosso minerador e acompanhar o progresso da mineração diretamente em nosso site.
                        </Typography>
                    </Box>

                    {/* Como Participar */}
                    <Box maxWidth="800px" marginBottom={4}>
                        <Typography variant="h4" align="center" gutterBottom>
                            Como Participar
                        </Typography>
                        <Typography variant="body1" paragraph>
                            O processo para começar a minerar é simples e acessível:
                        </Typography>
                        <ol>
                            <li>
                                <Typography variant="body1">
                                    <strong>Cadastro:</strong> Crie sua conta no nosso site para começar.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Download:</strong> Baixe o minerador para o seu dispositivo. Disponível para <strong>Windows</strong> e <strong>Android</strong>. Em breve, também será compatível com <strong>Linux</strong>.
                                </Typography>
                            </li>
                            <li>
                                <Typography variant="body1">
                                    <strong>Acompanhe:</strong> Monitore o progresso da sua mineração diretamente no painel do site.
                                </Typography>
                            </li>
                        </ol>
                    </Box>

                    {/* Informações Adicionais */}
                    <Box maxWidth="800px" marginBottom={4}>
                        <Typography variant="h4" align="center" gutterBottom>
                            Sobre o Puzzle das Carteiras Bitcoin
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Em 2015, foi criado um desafio para medir a capacidade computacional da comunidade. Ele consiste em encontrar as chaves privadas de carteiras específicas, que possuem prêmios em Bitcoin. Cada chave encontrada desbloqueia o valor correspondente.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            O Puzzle #67 é um dos desafios restantes e possui um prêmio significativo. Com a nossa solução de mineração distribuída, estamos trabalhando para resolver este e outros puzzles no futuro.
                        </Typography>
                    </Box>

                    {/* Chamada para Ação */}
                    <Box maxWidth="800px" textAlign="center" marginTop={4}>
                        <Typography variant="h5" gutterBottom>
                            Pronto para começar?
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Faça parte dessa comunidade e contribua para quebrar o próximo desafio!
                        </Typography>
                        <Box display="flex" gap={2} justifyContent="center">
                            <Button variant="contained" color="primary" onClick={() => setInicio(false)}>
                                Login
                            </Button>
                            <Button variant="outlined" color="primary" onClick={() => setInicio(false)}>
                                Cadastre-se Agora
                            </Button>
                        </Box>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
};
