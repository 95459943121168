import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { useDrawerContext } from '../shared/contexts';
import {
  Dashboard,
  DivisaoDeLucros,
  Download,
  SobreNos,
  SobreProjeto,
} from '../pages';
import { TabelaDePremios } from '../pages/tabeladepremios/TabelaDePremios';
import ListaIndicados from '../pages/TesteIndicados/ListaIndicados'; // Nova importação
import Registrar from '../pages/TesteIndicados/Registrar'; // Nova importação

export const AppRoutes = () => {
  const { setDrawerOptions } = useDrawerContext();

  useEffect(() => {
    setDrawerOptions([
      {
        icon: 'home',
        path: '/pagina-inicial',
        label: 'Página inicial',
      },
      {
        icon: 'info',
        path: '/sobre-Projeto',
        label: 'Sobre o Projeto',
      },
      {
        icon: 'people',
        path: '/sobre-nos',
        label: 'Sobre Nós',
      },
      {
        icon: 'military_tech',
        path: '/premiacao',
        label: 'Premiação',
      },
      {
        icon: 'download',
        path: '/download',
        label: 'Download',
      },
      {
        icon: 'group',
        path: '/indicados',
        label: 'Indicados', // Nova opção no menu
      },
    ]);
  }, [setDrawerOptions]);

  return (
    <Routes>
      <Route path="/pagina-inicial" element={<Dashboard />} />
      <Route path="/download" element={<Download />} />
      <Route path="/sobre-Projeto" element={<SobreProjeto />} />
      <Route path="/sobre-nos" element={<SobreNos />} />
      <Route path="/premiacao" element={<DivisaoDeLucros />} />
      <Route path="/indicados" element={<ListaIndicados />} /> {/* Nova rota */}
      <Route path="/registrar" element={<Registrar />} /> {/* Nova rota */}
      <Route path="*" element={<Navigate to="/pagina-inicial" />} />
    </Routes>
  );
};
