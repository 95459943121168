import { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, List, ListItem, ListItemText, CircularProgress } from '@mui/material';
import { Pie } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend
} from 'chart.js';
import { IUserProcessing, IUminersOnline, UserService } from '../../shared/services/api/users/UserService';
import { FerramentasDaListagem } from '../../shared/components';
import { LayoutBaseDePagina } from '../../shared/layouts';

// Registre os elementos necessários para o Pie Chart
ChartJS.register(ArcElement, Tooltip, Legend);

export const Dashboard = () => {
  const [isLoadingProcessing, setIsLoadingProcessing] = useState(true);
  const [isLoadingMiners, setIsLoadingMiners] = useState(true);

  const [userProcessing, setUserProcessing] = useState<IUserProcessing | null>(null);
  const [onlineUsers, setOnlineUsers] = useState<number| null>(null);

  // Busca o processamento do usuário
  useEffect(() => {
    setIsLoadingProcessing(true);
    UserService.getUserProcessing()
      .then((result) => {
        setIsLoadingProcessing(false);

        if (result instanceof Error) {
          alert(result.message);
        } else {
          setUserProcessing(result);
        }
      });
  }, []);

  // Busca os mineradores online
  useEffect(() => {
    setIsLoadingMiners(true);
    UserService.getOnlineUsers()
      .then((result) => {
        setIsLoadingMiners(false);
console.log("ERRO AQI ")
        if (result instanceof Error) {
          alert(result.message);
        } else {
          setOnlineUsers(result);
        }
      });
  }, []);

  const pieData = {
    labels: ['Meu Processamento', 'Restante'],
    datasets: [
      {
        data: userProcessing
          ? [
            Number(userProcessing.quantity), // Meu processamento
            Number(userProcessing.total) - Number(userProcessing.quantity), // Restante
          ]
          : [0, 0],
        backgroundColor: ['#FF6384', '#36A2EB'],
      },
    ],
  };

  return (
    <LayoutBaseDePagina
      titulo="Dashboard"
      barraDeFerramentas={<FerramentasDaListagem mostrarBotaoNovo={false} />}
    >
      <Box width="100%" display="flex" flexWrap="wrap" justifyContent="center" gap={2} margin={2}>
        {/* Card de Processamento do Usuário */}
        <Card sx={{ width: '100%', maxWidth: 600 }}>
          <CardContent>
            <Typography variant="h5" align="center">
              Processamento do Usuário
            </Typography>

            <Box padding={2} display="flex" flexDirection="column" alignItems="center">
              {!isLoadingProcessing && userProcessing && (
                <>
                  <Typography variant="body1">
                    <strong>Usuário:</strong> {userProcessing.userToken}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Meu Processamento:</strong> {userProcessing.quantity.toLocaleString()}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Token Energia:</strong> {userProcessing.energy.toLocaleString()}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Processamento Total:</strong> {userProcessing.total.toLocaleString()}
                  </Typography>
                  <Typography variant="body1">
                    <strong>Mineradores Online:</strong> {onlineUsers}
                  </Typography>

                  <Box marginTop={2} width="100%" display="flex" justifyContent="center">
                    <Pie
                      data={pieData}
                      options={{
                        responsive: true,
                        maintainAspectRatio: false,
                        plugins: {
                          legend: {
                            position: 'top',
                          },
                          tooltip: {
                            callbacks: {
                              label: function (context) {
                                const dataset = context.dataset.data;
                                const currentValue = Number(dataset[context.dataIndex]);
                                const totalValue = dataset.reduce((sum, value) => sum + Number(value), 0);
                                const percentage = ((currentValue / totalValue) * 100).toFixed(2);
                                return `${currentValue.toLocaleString()} (${percentage}%)`;
                              },
                            },
                          },
                        },
                      }}
                      height={200}
                      width={200}
                    />
                  </Box>
                </>
              )}
              {isLoadingProcessing && (
                <Typography variant="h6">Carregando...</Typography>
              )}
            </Box>
          </CardContent>
        </Card>
      </Box>
    </LayoutBaseDePagina>
  );
};
