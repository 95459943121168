import { createTheme } from '@mui/material';
import { yellow, cyan, purple, lightGreen } from '@mui/material/colors';

export const LightCyberpunkTheme = createTheme({
  palette: {
    mode: 'light',
    primary: {
      main: yellow[700],
      dark: yellow[800],
      light: yellow[500],
      contrastText: '#ffffff',
    },
    secondary: {
      main: cyan[600],
      dark: cyan[700],
      light: cyan[300],
      contrastText: '#ffffff',
    },
    background: {
      paper: '#ffffff',
      default: '#f5f5f5',
    },
    text: {
      primary: '#000000', // Texto preto para contraste no fundo claro
      secondary: purple[400],
    },
  },
  typography: {
    fontFamily: "'Roboto', sans-serif",
    button: {
      textTransform: 'none',
    },
  },
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          border: '2px solid',
          borderColor: yellow[700],
          textShadow: '0 0 5px #fff',
          '&:hover': {
            backgroundColor: yellow[600],
            borderColor: yellow[500],
            textShadow: '0 0 10px #fff',
          },
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: '#ffffff',
          color: cyan[700],
          boxShadow: '0 0 8px rgba(0,0,0,0.2)',
        },
      },
    },
  },
});
