import { Api } from '../axios-config';

export interface IRegisterUserDto {
  email: string;
  password: string;
  fullName: string;
  indication?: string;
}

export interface IUserInterface {
  id: number;
  email: string;
  cidadeId: number;
  nomeCompleto: string;
  enabled: boolean;
  createdAt: string;
  updatedAt: string;
}

export interface IUserProcessing {
  userToken: string;
  quantity: string; // BigInteger representado como string
  energy: string; // BigDecimal representado como string
  total: string;
  email: string;
  fullName: string;
  createdAt: string;
}
export interface IUminersOnline {
  id: string,
  fullName: string;
}
export interface IUIndication {
  id: string,
  fullName: string;

}
export interface IUIndicationList {
  id: string,
  indications: IUIndication[];

}

const getMe = async (): Promise<IUserInterface | Error> => {
  try {
    const { data } = await Api.get<IUserInterface>('/users/me');

    if (data) {
      return data;
    }

    return new Error('Erro ao obter os dados do usuário.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao obter os dados do usuário.');
  }
};

const getUserProcessing = async (): Promise<IUserProcessing | Error> => {
  try {
    const { data } = await Api.get<IUserProcessing>('/users/process');

    if (data) {
      return data;
    }

    return new Error('Erro ao obter o processamento do usuário.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao obter o processamento do usuário.');
  }
};
const getOnlineUsers = async (): Promise<number | Error> => {
  try {
    console.log("AQUI dsdsd");
    const { data } = await Api.get<number>('/users/miners');

    // Verifique se 'data' é um número válido
    if (typeof data === 'number' && !isNaN(data)) {
      return data; // Retorna o número se for válido
    }

    // Se 'data' não for um número válido, retorna um erro
    return new Error('Erro ao obter o processamento do usuário. Valor retornado não é um número válido.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao obter o processamento do usuário.');
  }
};

const getIndication = async (): Promise<IUIndicationList | Error> => {
  try {
    const { data } = await Api.get<IUIndicationList>(`/users/indication`);
    console.log(data)

    if (data) {
      return data;
    }

    return new Error('Erro ao obter o processamento do usuário.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao obter o processamento do usuário.');
  }
};


const register = async (registerUserDto: IRegisterUserDto): Promise<IUserInterface | Error> => {
  try {
    const { data } = await Api.post<IUserInterface>('/auth/signup', registerUserDto);

    if (data) {
      return data;
    }

    return new Error('Erro ao cadastrar o usuário.');
  } catch (error) {
    console.error(error);
    return new Error((error as { message: string }).message || 'Erro ao cadastrar o usuário.');
  }
};

export const UserService = {
  getMe,
  getUserProcessing,
  register,
  getIndication,
  getOnlineUsers,
};
