import React from 'react';
import { Button } from '@mui/material';

interface DownloadButtonProps {
  fileUrl: string;
  fileName: string;
  buttonText: string;
  style?: React.CSSProperties;  // Estilo personalizado opcional
}

const DownloadButton: React.FC<DownloadButtonProps> = ({ fileUrl, fileName, buttonText, style }) => {
  const handleDownload = () => {
    // Cria um link de download
    const link = document.createElement('a');
    link.href = fileUrl;
    link.download = fileName;

    // Simula o clique no link
    document.body.appendChild(link);
    link.click();

    // Remove o link após o download
    document.body.removeChild(link);
  };

  return (
    <Button 
      onClick={handleDownload} 
      variant="contained" 
      color="primary"
      style={{ padding: '10px 20px', fontSize: '16px', ...style }}  // Aplica o estilo personalizado
    >
      {buttonText}
    </Button>
  );
};

export default DownloadButton;
